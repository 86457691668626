<div>
    <section id="hero" class="text-center d-flex">
        <div class="col-md-8 hero-slide hide-mob"></div>
        <div class="col-md-4 right-set">
            <div class="hero-container">
                <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown;"> Connecting you</h1>
                <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
                    style="visibility: visible; animation-name: fadeInDown;"> to the help your <br> loved one needs.
                </h1>
                <div class="line-vt"></div>
                <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block" data-wow-delay=".0s"
                    style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown;">
                    Helplink will help you find support and services for someone you care about.</div>

                <div class="help-box wow animate__animated animate__fadeIn animate__slow mt2-4-600" data-wow-delay=".8s"
                    style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                    <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                </div>
            </div>
        </div>
    </section>
    <img src="../../../assets/bnr-my-loved-one-needs-help-mob.jpg" class="img-fluid mt-2 sw-mob">
    <section id="lovedoneneeds-reach-out">
        <!-- <div class="vt-bg-trans-logo"></div> -->
        <div class="bg-white-vt relative  myloved">
            <div class="container-fluid w-92  pt-5 pb-5">
                <div class="row ">
                    <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow">
                        <div class="sce-head1">Reach out.</div>
                        <div class="line-vt-100"></div>


                        <h2 class="font-weight-bold">My loved one needs help.</h2>
                    </div>
                    <div class="col-md-3" id="mainContent">
                        <h3 class="vt-m mt-3" [innerHTML]="contentFirst"></h3>
                        <p [innerHTML]="contentSecond  | safe"></p>
                        <p [innerHTML]="contentThree  | safe"></p>
                    </div>

                    <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                        <img src="../../../assets/my-loved-gethelp-1.jpg" class="img-fluid">
                        <h3 class="thmb-heading">Treatment and recovery. </h3>
                        <div class="line-vt"></div>
                        <p><small>Learn more about treatment and recovery services in Vermont, and what to expect.</small></p>
                        <div class="line-vt-100 mb-10"></div>
                        <a routerLink="/app/treatment-recovery" class="vt-color-2 link">Learn more.</a>
                    </div>

                    <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                        <img src="../../../assets/my-loved-gethelp-2.jpg" class="img-fluid">
                        <h3 class="thmb-heading">Prevent opioid overdose.</h3>
                        <div class="line-vt"></div>
                        <p><small>Take action to prevent overdose of heroin,
                                prescription painkillers, and other opioids. Learn the signs of overdose and how to use
                                naloxone (Narcan®) to reverse an overdose.</small></p>
                        <div class="line-vt-100 mb-10"></div>
                        <a routerLink="/app/overdose-prevention" class="vt-color-2 link">Take action.</a>
                    </div>

                </div>
            </div>

        </div>
    </section>
    <section id="lovedoneneeds-for-you">
        <div class="container-fluid  needhelp-sec3 w-100 bg-vt-welcome">
            <div class="row ">
                <div class="col-md-5 img-section-hfy-vt"></div>

                <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 p20 m0"
                    style="visibility: visible; animation-name: fadeInLeft;">
                    <div class="sce-head1">Here for you and those you care about.</div>
                    <div class="line-vt-100"></div>

                    <h2 class="font-weight-bold">We’re here to connect you to the support and services your loved one
                        needs.</h2>
                </div>
                <div class="col-md-3 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call "
                    style="visibility: visible; animation-name: fadeInLeft;">
                    <p><small>You might be concerned about someone, or struggling with substance
                            use yourself. Our caring, trained Specialists will answer your questions and guide you to
                            treatment options.</small></p>

                    <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40"
                        data-wow-delay=".8s">
                        <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                        <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                        <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>