import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-harm-reduction',
  templateUrl: './harm-reduction.component.html',
  styleUrls: ['./harm-reduction.component.scss']
})
export class HarmReductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
