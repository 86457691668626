
<div class="container-fluid ">
  <div class="row">
  <section id="inner-page" class="col-md-12">
  
      <div class="container covid-res">
        <div class="row"  id="hero">
      <div class="col-md-8">
          <div class="head01" id="covid19title">COVID-19 Resources for VT</div>
          <div class="line-vt mycs3"></div>
    
          <div id="covid19content" class="mycs24" [innerHTML]="preventioncontent"></div>    
      </div>
      <div class="col-md-4 right-set">    
    
       <div class="hero-container">
        <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown;"> Connecting you</h1>
        <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2" style="visibility: visible; animation-name: fadeInDown;"> to the services you need.</h1>
        <div class="line-vt"></div>
        <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block" data-wow-delay=".0s" >
        Helplink is committed to connecting you and those you care about to all available support and services.</div>
    
        <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
          <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
          <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
          <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
       </div>
    
      </div>
   
     </div>
    </div>
    </div>
    
    <section id="about-section-connection" class="">
    
    <div class="chat-box"> </div>
    </section>
    
    
    </section>
    <div></div>