import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent {
  title: string;
  mainContent: string;
  headertag: string;
  constructor(private services: DataService) { }

  ngOnInit(): void {
    this.services.getAboutData().subscribe(res => {
      if (res){
this.title = res.title.rendered;
this.headertag = res.content.rendered.split('\n\n\n\n')[0];
this.mainContent = res.content.rendered.split('\n\n\n\n').slice(1,6).join(' ');
      }
    })
  }
}