<div>
    <section id="hero" class="text-center d-flex">
        <div class="col-md-8 hero-slide hide-mob"></div>
        <div class="col-md-4 right-set">
            <div class="hero-container">
                <h1 class="wow animate__animated animate__fadeInDown animate__slow " style="visibility: visible; animation-name: fadeInDown;"> Connecting your
                </h1>
                <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2" style="visibility: visible; animation-name: fadeInDown;"> client
                    to the help <br>they need.</h1>
                <div class="line-vt"></div>
                <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block" data-wow-delay=".0s"
                    style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown;">Helplink will help you
                    help your clients find the services they need. We’re here for you. And for them.</div>

                <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s"
                    style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                    <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                </div>
            </div>
        </div>
    </section>
    <img src="../../../assets/bnr-my-clint-needs-help-mob.jpg" class="img-fluid mt-2 sw-mob">
    <section id="lovedoneneeds-reach-out">
        <div class="bg-white-vt relative myclient">
            <div class="container-fluid w-92  pt-5 pb-5">
                <div class="row ">

                    <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow" style="visibility: visible; animation-name: fadeInLeft;">
                        <div class="sce-head1">Reach out.</div>
                        <div class="line-vt-100"></div>
                        <h2 class="font-weight-bold">My client needs help.</h2>
                    </div>
                    <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow" style="visibility: visible; animation-name: fadeIn;">
                        <div id="mainContent">
                            <h3 class="vt-m mt-3" [innerHTML]="contentFirst"></h3>
                            <p [innerHTML]="contentSecond"></p>
                            <p [innerHTML]="contentThird"></p>
                        </div>
                    </div>
                    <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                        <img src="../../../assets/my-loved-gethelp-1.jpg" class="img-fluid">
                        <h3 class="thmb-heading">Treatment and recovery. </h3>
                        <div class="line-vt"></div>
                        <p class="min-h-70"><small> more about treatment and recovery services and
                                how Vermont supports people through their treatment and recovery path.</small></p>
                        <div class="line-vt-100 mb-10"></div>
                        <a routerLink="/app/treatment-recovery" class="vt-color-2 link">Learn more. </a>
                    </div>

                    <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                        <img src="../../../assets/my-loved-gethelp-2.jpg" class="img-fluid">
                        <h3 class="thmb-heading">Prevent opioid overdose.</h3>
                        <div class="line-vt"></div>
                        <p><small>Take action to prevent overdose of heroin,
                                prescription painkillers, and other opioids. Ensure that your staff and clients know the
                                signs of overdose and how to use naloxone (Narcan®) to reverse an overdose.</small></p>
                        <div class="line-vt-100 mb-10"></div>
                        <a routerLink="/app/overdose-prevention" class="vt-color-2 link">Take action. </a>
                    </div>

                </div>
            </div>

        </div>
    </section>
    <section id="lovedoneneeds-for-you">
        <div class="container-fluid needhelp-sec3">
            <div class="row ">
                <div class="col-md-5 img-section-hfy-vt"></div>

                <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
                    <small>Working for you</small>
                    <div class="line-vt-100"></div>

                    <h2 class="font-weight-bold">We’re here to connect your clients to the services available to them.
                    </h2>

                </div>
                <div class="col-md-4 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call ">
                    <p><small>Consider us a link between you and the services your clients need.
                            Our caring, trained Specialists will answer their questions and guide them to all available
                            treatment options.</small></p>

                    <div class="help-box wow animate__animated animate__fadeIn animate__slow  mt-40" data-wow-delay=".8s">
                        <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                        <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                        <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                    </div>
                </div>

            </div>

        </div>
    </section>
</div>