<div>
    <section id="hero" class="text-center d-flex relative">
        <div class="col-md-8 hero-slide hide-mob"></div>
        <div class="col-md-4 right-set">
            <div class="hero-container">
                <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown;"> Connecting you</h1>
                <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2" style="visibility: visible; animation-name: fadeInDown;"> to the
                    prevention<br> you need.</h1>
                <div class="line-vt"></div>
                <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block" data-wow-delay=".0s"
                    style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown;">
                    Helplink is your connection to prevention support and services.</div>

                <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s"
                    style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                    <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                </div>
            </div>
        </div>


        <div class="vt-bg-trans-logo"></div>
    </section>
    <!-- /euf/assets/client/img/bg-free-material.jpg -->
    <img src="../../../assets/bnr-general-prevention.jpg" class="img-fluid mt-2 mb-2 sw-mob">



    <section id="prescription-reach-out">

        <div class="bg-white-vt relative pre-section-bg">
            <div class="container-fluid w-92  pt-5 pb-5">
                <div class="row ">
                    <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow" style="z-index: 9">
                        <div class="sce-head1">Your connection to prevention.</div>
                        <div class="line-vt-100"></div>


                        <h2 class="font-weight-bold" id="prevention-title">Prevention</h2>
                        <div class="line-vt"></div>
                        <div id="prevention-content" [innerHTML]="contentFirst">

                        </div>
                        <p [innerHTML]="contentSecond"></p>
                    </div>
                    <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
                        <div id="prevention-sub-content" [innerHTML]="subContent"></div>
                    </div>


                </div>
            </div>
        </div>
    </section>
    <img src="../../../assets/bnr2-prevention-mob.jpg" class="img-fluid mt-2 mb-2 sw-mob">
    <section id="prescription-box-4" class="box-general-prevention">
        <div class="container-fluid w-92  card-p mt-40">
            <div class="row">
                <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                    <img src="../../../assets/prevention-1.jpg" class="img-fluid">
                    <h3 class="thmb-heading">Prescription medications disposal. </h3>
                    <div class="line-vt"></div>
                    <p class="line-h-16 mt-4 min-h-70"><small>Take action to prevent misuse of prescription medications.
                            Vermont makes is easy to get rid of unused medications.</small></p>
                    <div class="line-vt-100 mb-10"></div>
                    <a href="https://survey.alchemer.com/s3/5089490/1545c40385eb" target="_blank" class="vt-color-2 link">Request a free
                        prepaid drug disposal envelope</a>
                    <a href="https://www.healthvermont.gov/alcohol-drugs/services/prescription-drug-disposal"
                        class="vt-color-2 link" target="_blank"><span class="d-lap">Drop unused medication at your local
                            disposal kiosk</span></a>
                    <a href="https://www.healthvermont.gov/response/safe-needle-disposal-vermont" target="_blank"
                        class="vt-color-2 link">Found a needle? Here’s what to do.</a>
                </div>

                <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                    <img src="../../../assets/prevention-2.jpg" class="img-fluid">
                    <h3 class="thmb-heading">Safe use of prescription opioids</h3>
                    <div class="line-vt"></div>
                    <p class="line-h-16 mt-4 min-h-70"><small>Ask key questions before taking prescription opioids and
                            hear stories about opioid misuse.
                        </small></p>
                    <div class="line-vt-100 mb-10"></div>
                    <a href="https://www.healthvermont.gov/RxAware" target="_blank" class="vt-color-2 link">RxAware</a>
                </div>
                <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                    <img src="../../../assets/prevention-3.jpg" class="img-fluid">
                    <h3 class="thmb-heading">Community Prevention Programs. </h3>
                    <div class="line-vt"></div>
                    <p class="line-h-16 mt-4 min-h-70"><small>Learn about resources, guidance, and funding opportunities
                            to help protect and promote the health of Vermonters in your community.</small></p>
                    <div class="line-vt-100 mb-10"></div>
                    <a href="https://www.healthvermont.gov/alcohol-drug-abuse/programs-services/prevention-programs"
                        target="_blank" class="vt-color-2 link">Community Partners.</a>
                </div>

                <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
                    <img src="../../../assets/prevention-4.jpg" class="img-fluid">
                    <h3 class="thmb-heading">Family resources.</h3>
                    <div class="line-vt"></div>
                    <p class="line-h-16 mt-4 min-h-70"><small>Strong family connections help to reduce youth alcohol and
                            drug use. Learn about family programs that promote healthy family relationships and
                            strengthen parenting skills.</small></p>
                    <div class="line-vt-100 mb-10"></div>
                    <a href="https://www.parentupvt.org/" target="_blank" class="vt-color-2 link">Parent Up. </a>
                    <!-- <a href="https://www.healthvermont.gov/sites/default/files/documents/pdf/ADAP_Rocking%20Horse%20Program%20Overview.pdf"
                        target="_blank" class="vt-color-2 link">Project Rocking Horse</a> -->

                </div>
            </div>
        </div>

    </section>
    <section id="prescription-for-you" class="mt-40 relative gen-preven">
        <div class="vt-bg-trans-logo-rev"></div>
        <div class="container-fluid w-100  ">
            <div class="row ">
                <div class="col-md-5 mycshide  img-section-hfy-vt"></div>

                <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
                    <div class="sce-head1">Prevention is possible.</div>
                    <div class="line-vt-100"></div>

                    <h2 class="font-weight-bold">We’re here to connect you to the prevention resources you need.</h2>

                </div>
                <div class="col-md-4 mycs4 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call ">
                    <p class="line-h-20 mt-2"><small>You might be concerned about someone, or struggling with substance
                            use yourself. Our caring, trained Specialists will answer your questions and guide you to
                            the prevention products and services you need.</small></p>

                    <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40"
                        data-wow-delay=".8s">
                        <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                    </div>
                </div>

            </div>

        </div>
    </section>
</div>