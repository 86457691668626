import { Component, OnInit } from '@angular/core';
import { ContentDataResolver } from 'src/app/resolvers/contentdata.resolver';

@Component({
  selector: 'app-general-prevention',
  templateUrl: './general-prevention.component.html',
  styleUrls: ['./general-prevention.component.scss']
})
export class GeneralPreventionComponent implements OnInit {
  title: string;
  content: string;
  subContent: string;
  contentFirst: string;
  contentSecond: string;
  constructor(private services: ContentDataResolver) { }

  ngOnInit(): void {
    this.subContent = '';
    this.services.contentData.subscribe(res => {
      if (res){
        let data = res.find((ele: any) => ele.id === 42);
        this.title = data.title.rendered;
        this.contentFirst = data.content.rendered.split('\n\n\n\n')[0];
        this.contentSecond = data.content.rendered.split('\n\n\n\n')[1];
        this.subContent = data.acf.second_column;
              }
    })
  }
}
