<div ng-app="ineedhelphelpApp" ng-controller="ineedhelpController">
    <section id="hero" class="text-center d-flex">
        <div class="col-md-8 hero-slide hide-mob"></div>
        <div class="col-md-4 right-set">
            <div class="hero-container">
                <h1 class="wow animate__animated animate__fadeInDown animate__slow"
                    style="visibility: visible; animation-name: fadeInDown;"> Connecting you</h1>
                <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
                    style="visibility: visible; animation-name: fadeInDown;"> to the help you <br> need.</h1>
                <div class="line-vt"></div>
                <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block"
                    data-wow-delay=".0s" style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown;">
                    The Helplink is your connection. We’re here for you.</div>

                <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s"
                    style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                    <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                </div>
            </div>
        </div>
    </section>
    <img src="../../../assets/bnr-i-need-help-mob.jpg" class="img-fluid mt-2 sw-mob">
    <section id="lovedoneneeds-reach-out">
        <div class="bg-white-vt relative needhelp">
            <div class="container-fluid w-92  pt-5 pb-5">
                <div class="row ">

                    <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow"
                        style="visibility: visible; animation-name: fadeInLeft;">
                        <div class="sce-head1">Reach out.</div>
                        <div class="line-vt-100"></div>

                        <h2 class="font-weight-bold" id="ineedhelp-title" [innerHTML]="title">
                        </h2>
                    </div>
                    <div class="col-md-3 mycs4 wow animate__animated animate__fadeIn animate__slow" id="ineedhelp-content"
                        style="visibility: visible; animation-name: fadeIn;">
                        <h3 class="vt-m mt-3" [innerHTML]="ineedhelpFirstContent">
                        </h3>
                        <p [innerHTML]="ineedhelpScecondContent"></p>
                    </div>
                    <div class="col-md-3 mycs9 wow animate__animated animate__fadeIn animate__slow"
                        style="visibility: visible; animation-name: fadeIn;">
                        <img src="../../../assets/my-loved-gethelp-1.jpg" class="img-fluid">
                        <h3 class="thmb-heading">Treatment and recovery. </h3>
                        <div class="line-vt"></div>
                        <p class=" min-h-70"><small>Learn more about treatment and recovery services in
                                Vermont, and what to expect.</small></p>
                        <div class="line-vt-100 mb-10"></div>
                        <a routerLink="/app/treatment-recovery" class="vt-color-2 link">Learn more. </a>
                    </div>

                    <div class="col-md-3 mycs9 wow animate__animated animate__fadeIn animate__slow"
                        style="visibility: visible; animation-name: fadeIn;">

                        <img src="../../../assets/my-loved-gethelp-2.jpg" class="img-fluid">
                        <h3 class="thmb-heading">Prevent opioid overdose.</h3>
                        <div class="line-vt"></div>
                        <p><small>Take action to prevent overdose of heroin,
                                prescription painkillers, and other opioids – whether you use opioids or care about
                                someone who does.</small></p>
                        <div class="line-vt-100 mb-10"></div>
                        <a routerLink="/app/overdose-prevention" class="vt-color-2 link">Take action. </a>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section id="lovedoneneeds-for-you">
        <div class="container-fluid w-100  needhelp-sec3">
            <div class="row ">
                <div class="col-md-5 img-section-hfy-vt"></div>

                <div class="col-md-3 mycs6 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 pb0">
                    <div class="sce-head1" >The first step is reaching out.</div>
                    <div class="line-vt-100 mb-10"></div>

                    <h2 class="font-weight-bold mycs7">We’re here to connect you to the services you need.</h2>

                </div>
                <div class="col-md-4 mycs8 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call pb0">
                    <p class="mt-2"><small>You might be concerned about someone, or struggling with substance
                            use yourself. Our caring, trained Specialists will answer your questions and guide you to
                            treatment options that work for you.</small></p>

                    <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40"
                        data-wow-delay=".8s">
                        <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                    </div>
                </div>
            </div>
        </div>
    </section>