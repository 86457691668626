
<div class="container-fluid">
        <div class="row">
            <section id="inner-page" class="col-md-12">
                <div class="container">
    <div class="row">
        <div class="col-sm-7">
            <div class="head01">Reset your password</div>	
            <div class="partial-line"></div>
            <div id="err_location_fp"></div>	
            <div id="forgot_password">			
                <p class="top10">We'll email you a link to a page where you can create a new password.</p>
                <form id="forgot_password_form">
                    <div class="form-group">
                        <label for="un">Email Address</label>
                        <input type="text" class="form-control" id="un" name="un" />
                    </div>
                    <div class="form-group text-center">
                        <button class="btn-get-started hero-button">Submit</button>
                    </div>
                </form>
            </div>
            <div id="security_questions"></div>
            <div id="email_sent_fp"></div>
        </div>
        <div class="col-sm-5">
            <p class="top10">Upon submission of this form, if you don't receive an email: </p>
            <ul class="default_ul">
                <li>Make sure you're using the correct email address</li>
                <li>Your account may be disabled</li>
                <li>The email address you're checking may be different than the email on file in the system</li>
            </ul>
        </div>	
    </div>
    </div>