import { Component, OnInit } from '@angular/core';
import { ContentDataResolver } from 'src/app/resolvers/contentdata.resolver';

@Component({
  selector: 'app-needhelp',
  templateUrl: './needhelp.component.html',
  styleUrls: ['./needhelp.component.scss']
})
export class NeedhelpComponent implements OnInit {
title: string;
ineedhelpFirstContent: string;
ineedhelpScecondContent: string;
modifycontent: any;
  constructor(private services: ContentDataResolver) { }

  ngOnInit(): void {
    this.services.contentData.subscribe(res => {
      if (res !== null){
let data = res.find((ele: any) => ele.id === 31);
this.title = data.title.rendered;
this.modifycontent = data.content.rendered;
this.ineedhelpFirstContent = this.modifycontent.split('\n\n\n\n')[0];
this.ineedhelpScecondContent = this.modifycontent.split('\n\n\n\n')[1];
      }
    })
  }

}
