import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { NeedhelpComponent } from './pages/needhelp/needhelp.component';
import { LovedneedHelpComponent } from './pages/lovedneed-help/lovedneed-help.component';
import { ClientneedHelpComponent } from './pages/clientneed-help/clientneed-help.component';
import { AboutTreatmentComponent } from './pages/about-treatment/about-treatment.component';
import { PregnantParentingComponent } from './pages/pregnant-parenting/pregnant-parenting.component';
import { GeneralPreventionComponent } from './pages/general-prevention/general-prevention.component';
import { OverdosePreventionComponent } from './pages/overdose-prevention/overdose-prevention.component';
import { CovidResourcesComponent } from './pages/covid-resources/covid-resources.component';
import { ProviderLoginComponent } from './pages/provider-login/provider-login.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { FindhelpComponent } from './pages/findhelp/findhelp.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { SafePipe } from './pipes/sanatize.pipe';
import { AnswerComponent } from './answer/answer.component';
import { SpaResultComponent } from './spa-result/spa-result.component';
import { HarmReductionComponent } from './pages/harm-reduction/harm-reduction.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NeedhelpComponent,
    LovedneedHelpComponent,
    ClientneedHelpComponent,
    AboutTreatmentComponent,
    PregnantParentingComponent,
    GeneralPreventionComponent,
    OverdosePreventionComponent,
    CovidResourcesComponent,
    ProviderLoginComponent,
    ForgotpasswordComponent,
    FindhelpComponent,
    AboutusComponent,
    ContactusComponent,
    SafePipe,
    AnswerComponent,
    SpaResultComponent,
    HarmReductionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
