import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isShow: boolean = false;
  ngOnInit(): void {
  
  }
constructor(
  private route: Router
){}
navigate(): void {
  this.route.navigate(['/app/home'])
}
ngAfterViewInit(){
$("ul.navbar-nav").find("a").on("click",function(e:any){
  console.log(e.target.getAttribute("href"))
  if(e.target.getAttribute("href")){
    if(e.target.getAttribute("href").includes('app/')){
      $("button.navbar-toggler").click();
      // $("#navbarSupportedContent").removeClass("show");
    }
  }
});

}
}
