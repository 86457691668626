<div >
          <section id="hero"  class="text-center d-flex relative">
          <div class="col-md-8 hero-slide hide-mob" ></div>
          <div class="col-md-4 right-set">
                <div class="hero-container">
                <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown;"> Connecting you</h1>
        <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2" style="visibility: visible; animation-name: fadeInDown;"> to the treatment & recovery you need.</h1>
        <div class="line-vt"></div>
                <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block" data-wow-delay=".0s" style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown;">
                Consider us your connection to everything you need to know about treatment and recovery.</div>
              
                <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                    <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                </div>
              </div>
          </div>
            <div class="vt-bg-trans-logo"></div>
        </section>
        <img src="../../../assets/bnr-treatment_Recovery-mob.jpg" class="img-fluid sw-mob">
        <section id="treatment-recovery">
                <div class="vt-bg-trans-logo sw-mob"></div>
                
                <div class="bg-white-vt relative">
            <div class="container-fluid p-2 pr-0 pl-5 pt-5 pb-5" style="z-index: 9;    position: relative;">
                <div class="row ">	
                <div class="col-md-3 mycs4 p15 p-0 pl-5 wow animate__animated animate__fadeInLeft animate__slow">
                    <div class="sce-head1">Recovery is possible.</div>
            <div class="line-vt-100"></div>
            <h2 class="font-weight-bold">Treatment and recovery</h2>
            <div class="line-vt"></div>
            <div id="mainContent">
              <h3 class="vt-m mt-3" ></h3>
              <p [innerHTML]="leftContent"></p>
              </div>
                </div>
            
            
            <!-- accordion section  -->
            
            
                <div class="col-md-6 mycs4 wow animate__animated animate__fadeIn animate__slow">
                <div id="accordion" class="myaccordion"  [innerHTML]="content | safe">
              
            </div>
                </div>
                
                <div class="col-md-3 mycshide wow animate__animated animate__fadeIn animate__slow p-0">
                    <img src="../../../assets/img-treatment-recovery.jpg" class="img-fluid mt-50 m-mob-0">
                </div>
            </div>
            </div>
            </div>
            </section>
            <section id="lovedoneneeds-for-you">
                    <div class="container-fluid w-100  ">
        <div class="row">	
            <div class="col-md-5 img-section-hfy-vt hide-mob"></div>
        
            <div class="col-md-3 pt2-tab wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
                <div class="sce-head1">Here for you.</div>
        <div class="line-vt-100"></div>
        
        <h2 class="font-weight-bold">We’re here to connect you to the treatment & recovery information you need.</h2>
        
            </div>
            <div class="col-md-4 pt2-tab wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call ">		
                <p class="line-h-20 mt-2"><small>You might be concerned about someone, or struggling with substance use yourself. Our caring, trained Specialists will answer your questions and guide you to all available treatment options.</small></p>
        
                 <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
                          <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                            <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                            <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                        </div> 
            </div>
          <div class="col-md-5 img-section-hfy-vt sw-mob"></div>
            
        </div>
        
        </div>
        <!-- <div class="chat-box"> </div> -->
                </section>
        </div>
