import { Component, OnInit } from '@angular/core';
import { ContentDataResolver } from 'src/app/resolvers/contentdata.resolver';

@Component({
  selector: 'app-covid-resources',
  templateUrl: './covid-resources.component.html',
  styleUrls: ['./covid-resources.component.scss']
})
export class CovidResourcesComponent implements OnInit {
  accordion: string;
  preventioncontent: string;
  subContent: string;
  constructor(private services: ContentDataResolver) { }

  ngOnInit(): void {
    this.subContent = '';
    this.services.contentData.subscribe(res => {
      if (res){
        let data = res.find((ele: any) => ele.id === 129);
        this.accordion = data.content.rendered
        this.preventioncontent = data.content.rendered;
              }
      
    })
  }
}
