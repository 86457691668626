
    <section id="hero"  class="text-center d-flex home-sld">
            <div class="col-md-8 hero-slide hide-mob" ></div>
            <div class="col-md-4 right-set">
                    <div class="hero-container">
                        <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown;"> Connecting you</h1>
                        <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2" style="visibility: visible; animation-name: fadeInDown;"> to the services<br> you need.</h1>
                        <div class="line-vt home-line"></div>
                        <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block mb-30" data-wow-delay=".0s"
                            style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown;">
                            VT Helplink is your connection to Alcohol and
Drug Support Services. We're here for you.</div>
        
                        <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s"
                            style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                            <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                            <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                            <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                            <!-- <div class="bt1"><a href="tel:8025655465" class="btn-get-started hero-button">Call us.
                                    802.565.LINK</a>
                            </div>
                            <div class="bt2 top"><button class="btn-get-started hero-button btn-connect"
                                    onclick="showchat()">Connect via chat.</button>
                            </div> -->
                        </div>
                    </div>
                </div>
        </section>
        <!-- <img src="../../../assets/home-bg-black-mob.jpg" class="img-fluid sw-mob"> -->
        <img src="../../../assets/home-bg-black.png" class="img-fluid sw-mob">
                <div class="container-fluid w-100 pl-4p bg-vt-welcome relative">
                        <div class="row align-items-center">	
                            <div class="col-md-3 mycs12 wow animate__animated animate__fadeInLeft animate__slow">
                                <div class="sce-head1 sec-head-mob">Welcome.</div>
                                <div class="line-vt-100"></div>
                    
                                <h2 class="font-weight-bold mycl8">We're VT Helplink.</h2>
                                <h2 class="font-weight-bold mycs3">We’re here to connect you to the help that you need.</h2>
                                <div class="line-vt mycl7"></div>
                            </div>
                            
                            <div class="col-md-3 mycs14 wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block st-page">
                                <h3 class="vt-m mycl6">VT Helplink is your statewide, public resource for finding substance use treatment and recovery services in Vermont.</h3>
                                <p><small>Helplink services are free and confidential. Our caring, trained Specialists will help you or your loved one take a step toward recovery.</small></p>
                            </div>
                            
                            <div class="col-md-6 img-welcome-vt mycl9"></div>
                        </div>
                        <div class="vt-bg-trans-logo"></div>
                    </div>  
                    <div class="bg-white-vt box-4" style="background-color:#f6f2f1;">
                        <div class="container-fluid w-92  mycl13">
                            <div class="row align-items-center2">
                                <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
                                    <div class="sce-head1 sec-head-mob">Wherever you’re at</div>
                                    <div class="line-vt-100" style="margin: 20px 0 40px 0;"></div>    
                                    <h2 class="font-weight-bold">Find help.</h2>
                                    <div class="line-vt mycl10"></div>
                                    <h3 class="vt-m mt-3  mycl6">Recovery looks different for everyone. </h3>
                                    <p><small>Wherever you are at the moment, we’ll connect you with the services you need.
                                    </small></p>
                                    <a href="https://hub.vthelplink.org/findhelp" target="_blank" class="vt-color-2 link mycl12">Search for services.</a> 
                                </div>
                                <div class="col-md-3 mycs5 mycl11 wow animate__animated animate__fadeIn animate__slow inm-mt4">
                                    <img src="../../../assets/treatment.png" class="img-fluid">
                                    <h3 class="thmb-heading">Treatment</h3>
                                    <div class="line-vt mycl10"></div>
                                    <p class="tretment-cnt para-margin"><small>Residential treatment includes monitored detoxification, medical care, and 24-hour support in a live-in setting.  Find treatment and recovery services for alcohol and other substances at one of Vermont’s residential treatment centers here.</small></p>
                                    <div class="line-vt-100 mb-10"></div>
                                    <a href="https://hub.vthelplink.org/spa_result/incident_id/34a60db3-e7c0-4f36-9a41-b76fe24ddd3c" target="_blank" class="vt-color-2 link mycl12">Search for Residential Treatment.</a>
                                </div>
                                <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
                                    <img src="../../../assets/medications.png" class="img-fluid">
                                    <h3 class="thmb-heading">Medications for Substance Use Disorders</h3>
                                    <div class="line-vt  mycl10 medication-bar"></div>
                                    <p class="para-margin"><small>Medications for Substance Use Disorders are the use of prescription medicine, like Suboxone® or Antabuse®, to treat substance use and support recovery.<br/><br/></small></p>
                                    <div class="line-vt-100 mb-10"></div>
                                    <a href="https://hub.vthelplink.org/spa_result/incident_id/00c96ceb-7e4d-45ea-9300-dacb1db4853a" target="_blank" class="vt-color-2 link  mycl12">Find medication resources here.</a>
                                </div>
                                <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
                                    <img src="../../../assets/harmreduction.png" class="img-fluid">
                                    <h3 class="thmb-heading">Harm Reduction / Safer Use</h3>
                                    <div class="line-vt  mycl10"></div>
                                    <p class="para-margin"><small>If you or a loved one is using drugs, we want to support you using in safer ways. Search for providers who understand and provide services like syringe exchange, fentanyl test strips, Narcan® (naloxone), and much more.<br/><br/></small></p>
                                    <div class="line-vt-100 mb-10"></div>
                                    <a href="https://hub.vthelplink.org/spa_result/incident_id/2067e362-25c4-4256-86bc-6ce3e4000078" target="_blank" class="vt-color-2 link  mycl12">Search for Harm Reduction / Safer Use services.</a>
                                </div>
                            
                            </div>
                        </div>
                    </div>                  
<div class="bg-white-vt box-4" style="background-color:#fcf8f9;">
        <div class="container-fluid w-92  mycl13">
            <div class="row align-items-center2">
                <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
                    <div class="sce-head1 sec-head-mob">Call or chat with us.</div>
                    <div class="line-vt-100 middle-line" style="margin: 20px 0 40px 0;"></div>    
                    <h2 class="font-weight-bold">Reach out.</h2>
                    <div class="line-vt mycl10"></div>
                    <h3 class="vt-m mt-3  mycl6">Whether you’re seeking help for yourself or someone you care about, VT Helplink can connect you to the services you need.  </h3>
                    <p><small>VT Helplink is a free resource for Vermonters, providing confidential, non-judgmental support and referrals to treatment, recovery, and other services.</small></p>
                    <p><small>Our Helplink Specialists can help you navigate Vermont’s treatment and recovery system, whether or not you have insurance.</small></p>
                </div>
                <div class="col-md-3 mycs5 mycl11 wow animate__animated animate__fadeIn animate__slow inm-mt4 ">
                    <img src="../../../assets/gethelp-1.jpg" class="img-fluid">
                    <h3 class="thmb-heading">I need help.</h3>
                    <div class="line-vt mycl10"></div>
                    <p class="help-content para-margin"><small>We are here to help. We’ll listen to you and connect you to the services you need.</small></p>
                    <div class="line-vt-100 mb-10"></div>
                    <a routerLink="/app/needhelp" class="vt-color-2 link mycl12">Get help for yourself.</a>
                </div>
                <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
                    <img src="../../../assets/gethelp-2.jpg" class="img-fluid">
                    <h3 class="thmb-heading">My loved one needs help.</h3>
                    <div class="line-vt  mycl10"></div>
                    <p class="loved-content para-margin"><small>Learn how you can support someone you care about, and how to care for yourself, too.</small></p>
                    <div class="line-vt-100 mb-10"></div>
                    <a routerLink="/app/my-loved-one-needs-help" class="vt-color-2 link  mycl12">Get help for your loved one. </a>
                </div>
                <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
                    <img src="../../../assets/gethelp-3.png" class="img-fluid">
                    <h3 class="thmb-heading">My client needs help. </h3>
                    <div class="line-vt  mycl10"></div>
                    <p class="para-margin"><small>We can help you assess your clients’ needs and provide referrals to appropriate services.</small></p>
                    <div class="line-vt-100 mb-10"></div>
                    <a routerLink="/app/my-client-needs-help" class="vt-color-2 link  mycl12">Get help for your client. </a>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid w-100 pl-4p bg-vt-welcome relative">
            <!-- <div class="row align-items-center"></div> -->
            <div class="row">	
                <div class="col-md-3 col-sm-12 mycs16 wow animate__animated animate__fadeInLeft animate__slow op-overdose staysafe-col">
                    <div class="sce-head1 sec-head-mob mt-10">Stay safe.</div>
                    <div class="line-vt-100"></div>
                    <a [routerLink]="['/app/saferuse']" style="color: #000;"><h2 class="font-weight-bold">Safer use.</h2></a>
                    <div class="line-vt home-line"></div>
                    <p><small>No matter where you’re at, there are people and resources to help you use more safely.</small></p>
                    <!-- <div class="line-vt mycl10"></div> -->
                </div>
        
                <div class="col-md-3 col-sm-12 mycs15 mt wow animate__animated animate__fadeIn animate__slow staysafe-col">
                    <h3 class="vt-m col-sm-12 mt46-sec4 p0m0 mycl6">Narcan® (naloxone)</h3>
                    <p class="mt-3 home-p"><small>Opioid overdoses can be prevented, and anyone can  save a life with Narcan®, the opioid overdose reversal drug. You can find Narcan® in programs and pharmacies across the state.</small></p>
                        <a href="https://hub.vthelplink.org/spa_result/incident_id/0fb64a4d-10bb-4058-bd31-1150a2eedbf2" target="_blank" class="vt-color-2 link mb-1 mycl14 color-link">Get Narcan®.</a>
                        <h3 class="vt-m col-sm-12 mt46-sec4 p0m0 mycl6">Harm Reduction Packs</h3>
                    <p class="mt-3 home-p"><small>Harm Reduction Packs carry lifesaving supplies like Narcan®, fentanyl test strips, and other products to keep you safer. You can pick these up at locations across the state or request a mobile van come to you.</small></p>
                        <a href="https://hub.vthelplink.org/spa_result/incident_id/0794a21e-0ee1-4be4-9abe-5ffca2387a18" target="_blank" class="vt-color-2 link mb-1 mycl14 color-link">Get Harm Reduction Packs.</a>
                        <h3 class="vt-m col-sm-12 mt46-sec4 p0m0 mycl6">New Syringes</h3>
                    <p class="mt-3 home-p"><small>Using new syringes can help prevent disease. You can visit Syringe Service Providers across the state to access new syringes and other harm reduction services, or request a mobile van to come to you.</small></p>
                        <a href="https://hub.vthelplink.org/spa_result/incident_id/d09981e8-eb80-40c0-b306-fd05b067faf0"  target="_blank" class="vt-color-2 link mb-1 mycl14 color-link">Get more information about new syringes.</a>
                        <!-- <a href="https://vthelplink.org/app/account/opa_result/incident_id/0169bac2-7560-4d0a-afbf-79eb3e86f6a5#description"  class="vt-color-2 link mb-1 mycl14">Get naloxone.</a> 
                        <a routerLink="/app/overdose-prevention"  class="vt-color-2 link mycl14">Learn more about overdose prevention.</a>  -->
                </div>
                <div class="col-md-6 img-section-preventing-opioid mycl9 mymt2"></div>
            </div>
            <div class="vt-bg-trans-logo"></div>
        </div>
        <div class="container-fluid w-100 bg-vt-pop">
                <div class="row ">	
                    <div class="col-md-5 img-section-pop-vt hide-mob mycs20"></div>
            
                    <div class="col-md-3 mycs17 wow animate__animated animate__fadeInLeft animate__slow pt-5 op-overdose">
                        <div class="sce-head1 sec-head-mob">Get resources for you and your family.</div>
                        <div class="line-vt-100"></div>
            
                        <h2 class="font-weight-bold">Are you pregnant or parenting?</h2>
                        <div class="line-vt mycl10"></div>
                    </div>
            
                    <div class="col-md-3 mycs18 wow animate__animated animate__fadeInDown animate__slow  d-sm-block pt-6">
                        <h3 class="vt-m mt16-sec5 pt-2-vo1024 mycl6">Whether you’re a parent, or soon to be one, VT Helplink can connect you to the resources you need. </h3>
                        <p class="mt-3 mycl14"><small>Many treatment options and other supports are available to help you and your family stay healthy and stay together.</small></p>
                            <a routerLink="/app/pregnant-parenting" class="vt-color-2 link mycl14" >See pregnancy & parenting resources.</a> 
                    </div>
            
                    <img src="../../../assets/bg-vt-section-pregnant.jpg" class="col-md-6 mycs19 img-fluid sw-mob m-l-r--15 mymt2">
            </div>
            </div>
            <div class="relative bg-white-vt p0m0" id="bg-wheat">
                    <div class="container-fluid w-100 myclas2">
                            <div class="row ">	
                        
                <div class="ml-4-pt"></div>
                        <div class="col-md-3 mycs21 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 mmt-2 op-overdose">
                            <div class="sce-head1 sec-head-mob">Spread the word.</div>
                            <div class="line-vt-100"></div>
                
                            <h2 class="font-weight-bold" >Order free materials.</h2>
                            <div class="line-vt mycl10"></div>
                        </div>
                
                
                        <div class="col-md-3 mycs22 wow animate__animated animate__fadeInDown animate__slow  d-sm-block mt-40 pt-6 bt-call  mb-100">	
                            <h3 class="vt-m mycl6">Promote the use of the Helplink in your community.</h3>
                                     
                            <p><small>Help connect people to the services they need with VT Helplink cards, posters, and other items.</small></p>
                             <!-- <a href="https://vadic.org/product-category/vermont-resources/vt-helplink/" target="_blank" class="vt-color-2 link  mycl12">Order materials now. </a>  -->
                             
                        </div>
                        <div class="col img-free-material-vt"> </div>
                <div class="col-md-6 mycs19 sw-mob hide-l-992 mycl15">
                <img src="../../../assets/bg-free-material.jpg" class="img-fluid sw-mob">
                 </div>
                
                    </div>
                    </div>
                    <div class="vt-bg-trans-logo-rev"></div>
                </div>


                <div class="container-fluid w-100  bg-vt-welcome myclas2">
                        <div class="row ">	
                            <div class="col-md-5 mycs20 img-section-hfy-vt hide-mob"></div>
                    
                            <div class="col-md-3 mycl17 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 op-overdose">
                                <div class="sce-head1 sec-head-mob">Consider yourself connected.</div>
                                <div class="line-vt-100"></div>
                                <h2 class="font-weight-bold">You are not alone.</h2>
                                <div class="line-vt mycl10"></div>
                            </div>
                            <div class="col-md-3 mycl17 wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block mt-40 pt-6 bt-call ">		
                                <p><small>We're here to listen your story, understand your needs, and connect you to the services you need.</small></p>
                    
                                <div class="help-box wow animate__animated animate__fadeIn animate__slow  mt-40" data-wow-delay=".8s">
                                    <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a>
                                    </div>
                                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a>
                                    </div>
                                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button>
                                    </div>
                                </div> 
                            </div>
                            <div class="col-md-6 mycs19 sw-mob hide-l-992 mycl15">
                                <img src="../../../assets/bg-vt-section-hfy.png" class="img-fluid sw-mob">
                                 </div>
                            <!-- <img src="../../../assets/bg-vt-section-hfy.png" class="img-fluid mycs23 sw-mob m-l-r--15"> -->
                        </div>
                    
                    </div>