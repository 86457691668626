<div >
              <section id="hero"  class="text-center d-flex relative">
        <div class="col-md-8 hero-slide hide-mob" ></div>
        <div class="col-md-4 right-set">
         <div class="hero-container">
          <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown;"> Connecting you</h1>
          <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2" style="visibility: visible; animation-name: fadeInDown;"> to the services you need.</h1>
          <div class="line-vt"></div>
          <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block" data-wow-delay=".0s" >
          Helplink is committed to connecting you and those you care about to all available support and services.</div>
      
          <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s" style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
            <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
            <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
            <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
         </div>
       </div>
        </div>
       <div class="vt-bg-trans-logo"></div>  
      </section>
          <img src="../../../assets/bnr-about-us-mob.jpg" class="img-fluid sw-mob mt-2">
          <section id="lovedoneneeds-reach-out">
                <div class="bg-white-vt relative">
                <div class="container-fluid pr-0 pt5-abt pt-5 pb-5 w-92" style="z-index: 9;    position: relative;">
                 <div class="row ">	
                   <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
                    <div class="sce-head1">Let’s connect.</div>
                    <div class="line-vt-100"></div>
                    <h2 class="font-weight-bold" id="aboutus-title">About us</h2>
                    <div class="line-vt"></div>
                    <div id="mainContent">
                      <h3 class="vt-m mt-3" [innerHTML]="headertag"></h3>
                    </div>
                    <div id="mainContent" [innerHTML]="mainContent">
                  </div>      
                </div>
                <!-- accordion section start -->
                <div class="col-md-6 mycs10 wow animate__animated animate__fadeIn animate__slow">
                  <div id="accordion" class="myaccordion mycs23">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                          <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            Accessibility    test
                           
                          </button>
                        </h2>
                      </div>
                      <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                          <p>
                             <a href="https://www.vermont.gov/policies/accessibility" target="_blank">The Vermont Helplink website meets State of Vermont accessibility requirements</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                          <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                           Terms of use
                           
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div class="card-body">
                        <!--<h3>Assessment</h3>-->
                        <p>
                        Health Resources in Action and the State of Vermont maintain this website, VTHelplink.org, as a service to its clients and the community at large. We may revise and update these Terms and Conditions at any time. Your continued usage of the VTHelplink.org website (“VTHelplink.org Site”, or the “Site”) will mean you accept those changes.
                        </p>
            
                        <h3>The site does not provide medical advice or treatment.</h3>
                        <p>
                        The contents of the VTHelplink.org  Site, such as text, graphics, images, information obtained from  VTHelplink.org ’s licensors, and other material contained on the  VTHelplink.org Site (“Content”) are for informational purposes only. The Content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on the  VTHelplink.org Site. </p>
                        <h3>If you think you may have a medical emergency, call your doctor or 911 immediately.</h3>
                        <p>
                        VTHelplink.org does not recommend or endorse any specific tests, physicians, products, procedures, opinions or other information that may be mentioned on the Site. Reliance on any information provided by  VTHelplink.org , VTHelplink.org employees, other appearing on the Site at the invitation of  VTHelplink.org , or other visitors to the Site is solely at your own risk.
                        </p>
                        <p>
                          While VTHelplink.org will use reasonable efforts to include accurate and timely information on the Site, it makes no warranties or representations as to the accuracy of information or timeliness of resources presented on the Site or through links to other websites. VTHelplink.org shall not be held responsible for any action taken that is based on the information presented on its Site, and all users agree that access and use of the VTHelplink.org Site and Content presented will be at the user’s own risk.
                        </p>
                        <p>While VTHelplink.org will use reasonable efforts to include accurate and timely information on the Site, it makes no warranties or representations as to the accuracy of information or timeliness of resources presented on the Site or through links to other websites. VTHelplink.org shall not be held responsible for any action taken that is based on the information presented on its Site, and all users agree that access and use of the VTHelplink.org Site and Content presented will be at the user’s own risk.</p>
                        <h3>Links, resources, and references</h3>
                        <p>
                        This website contains references and links to information, products and services provided by third parties. These references and links are provided as aids to help you identify and locate other resources that may be of interest, and are not intended to state or imply that VTHelplink.org sponsors, endorses or is affiliated or associated with the persons or entities who provide such information, products or services, nor are the references or links intended to state or imply that VTHelplink.org is legally authorized to use any trade name, registered trademark, logo, legal or official seal, or copyright symbol that may be reflected in them.
                        </p>
                        <p>
                        Any communication transmitted to VTHelplink.org via the VTHelplink.org Site or through mail links enabled from this site may be accessed and viewed by other Internet users, without your knowledge and permission. Do not use links in this website to communicate private information or other information that you consider protected or confidential. When you access this site or submit information via this site, you consent to the collection, use, and disclosure of that information as described in our Privacy Policy, which may be found <a href="#">here</a>
                        </p>
                        <h3>Copyright</h3>
                        <p>
                        Material published in this web site is copyright owned by Health Resources in Action, Inc. unless otherwise noted. Such material is protected by U.S. and international copyright laws and treaties. All rights are reserved. If you find these materials useful, you may download, print out or send a copy to others but only for personal use or nonprofit uses. It is absolutely prohibited to reprint or electronically reproduce any text, document, graphic, or audio or visual material for commercial use. For special copyright permissions, please use the contact us form or VTHelplink.org c/o Health Resources in Action, Inc., 2 Boylston St, Boston, MA 02116.
                        </p>
                        <h3>Disclaimer of endorsement</h3>
                        <p>
                        The information posted on the VTHelplink.org Site includes information copyrighted by others, as well as hypertext links to other websites. These linked websites are developed and maintained independently and are not a part of the VTHelplink.org Site. When you leave the VTHelplink.org Site and travel to another website or web page, you become subject to the terms of use and privacy policies of those other websites. Neither VTHelplink.org nor any of is sponsoring agencies (or other affiliates) controls or guarantees the accuracy, relevance, timeliness, completeness of any content found on a linked website or endorses the views expressed, or products or services offered by, any third party information or linked website.
                        </p>
                        <h3>Disclaimer of liability and warranties</h3>
                        <p>
                          This Site and its contents are provided “as is” and use of the Site and the Content is at your own risk. We disclaim all warranties, express and implied, including the implied warranties of merchantability and of fitness for a particular purpose with respect to any information contained on, accessible through or derived by you from this site and with respect to any products or services purchased by you through this site. Without limiting the foregoing, VTHelplink.org , its licensors, and its suppliers make no representations or warranties about the accuracy, reliability, completeness, currentness, or timeliness of the Content, software, text, graphics, links or communications provided on or through the use of the  VTHelplink.org Site or VTHelplink.org . We likewise disclaim any and all liability to you arising out of interruption or damage to your computer system or software as the result of accessing or otherwise using this site. WE WILL NOT BE LIABLE FOR ANY INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES OF ANY KIND, INCLUDING LOST REVENUES, LOST PROFITS OR LOSS OF DATA ARISING OUT OF YOUR USE OF THIS SITE. In the event that any of the foregoing limitations and disclaimers is ineffective, you agree that our maximum liability to you shall be the total of the fees you may have paid to us in connection with your access to this site and/or for products or services you have purchased from us through this site.
                        </p>
                        <h3>Legal actions</h3>
                        <p>
                        These Terms of Use and the associated Privacy Policy shall be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts without regard to conflicts of laws principles, and you agree to submit to the personal jurisdiction of the courts of the Commonwealth of Massachusetts. In the event that any portion of these Terms of Use is deemed to a court to be invalid, the remaining provisions shall remain in full force and effect. You agree that regardless of any statute or law to the contrary, any clam or cause of action arising out of or related to this website, or the use of this website, must be filed within one year after such claim or cause of action arose and must be filed in a court in Massachusetts. If a court of competent jurisdiction should find that any provision or portion of these Terms of Use or the Privacy Policy is unenforceable, the remainder of each document will continue in full and effect.
                        </p>
                        <h3>Modifications to site</h3>
                        <p>
                          VTHelplink.org reserves the right, for any reason, in its sole discretion, to terminate, change or suspend any aspect of the site, including but not limited to, content, features or hours of availability. VTHelplink.org may impose limits on certain features of the site or restrict your access t part or all of the site without notice or penalty.
                        </p>
                        <h3>Miscellaneous</h3>
                        <p>
                        The Agreement comprises the entire agreement between you and VTHelplink.org and supersedes all prior agreements between the parties, regarding the subject matter contained herein. All provisions in the Agreement regarding representations and warranties, indemnification, disclaimers and limitation of liability shall survive any termination of this Agreement. VTHelplink.org reserves the right to change this Agreement at any time by posting a new Agreement on the Site. Your continued use of this site after such changes are posted will constitute your agreement with such changes.
                        </p>
                        <h3>Monitoring</h3>
                        <p>
                        You agree that VTHelplink.org has the right, but not the obligation to monitor, edit, disclose, refuse to post, or remove at any time, for any reason in its sole discretion, any material and content anywhere on the site including, but not limited to message boards, email and other forums. Notwithstanding this right,  VTHelplink.org does not and cannot review all materials posted to the Site by users and  VTHelplink.org is not responsible for any such materials posted by users.
                        </p>
                        <h3>Text Messaging</h3>
                        <p>This section applies when you provide prior express consent to send and receive text messages from <a href="https://vthelplink.org" style="color: #0052CC;">vthelplink.org.</a>  Text messaging from us may include one-time or recurring texts related to the information, products, and services referenced on this website or otherwise provided by third parties.  In all programs, you may text STOP to stop messaging for that program and HELP for help. Text messages may be sent to your mobile number using an automatic dialing system.   Message and Data rates may apply and are your sole responsibility. Text messaging may not be available via all carriers.</p>
                        <p>If you no longer want to receive text messages from us, the sole and exclusive remedy is to end enrollment in the specific texting program.</p>
                        <p>We do not guarantee the successful delivery of text messages by your wireless provider. Messages sent by text may not be delivered if the mobile device is not in range of a transmission site, or if sufficient network capacity is not available at a particular time. Even within a coverage area, factors beyond the control of wireless carriers may interfere with message delivery, including the terrain, proximity to buildings, foliage, weather, and the recipient’s equipment.  We will not be liable for losses or damages arising from (a) non-delivery, delayed delivery, or misdirected delivery of a text message; (b) inaccurate or incomplete content in a text message; or (c) use or reliance on the content of any text message for any purpose.</p>
                        <p>5250.2/671101.1</p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          Privacy
                         
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                      <div class="card-body">
                        <p>
                        Thank you for visiting our VTHelplink.org  website and reviewing our Privacy Policy.  This policy pertains to VTHelplink.org  and all VTHelplink.org funded websites. Your privacy is important to  VTHelplink.org. This privacy statement provides information about the personal information that VTHelplink.org collects, and the ways in which VTHelplink.org  uses that personal information.
                          </p>
                          <h3>Personal information collection <br/>
                          VTHelplink.org may collect and use the following kinds of personal information:</h3>
               <p>
            Non-personally identifiable information
            We may use non-personally identifiable information collected from you and other website users to perform statistical analyses of user behavior and characteristics. These aggregated statistics would be used to improve the site and for product development and marketing purposes generally. We also may provide such aggregated information to funding sources and other third parties for certain purposes, but such statistics contain no personally identifiable information and cannot be used to gather such information.</p>
            
            
            <p>Personally identifiable information
            The personally identifiable information you provide to us when using this site
             may be used to provide products and services to you, to enhance your experience at the site, and,
              if applicable, to enhance your experience as a user of  VTHelplink.org ’s products and services.
                Information about you may also be received by us when the site is accessed by you via various social media outlets.
                  Your privacy settings with the social network generally establish the amount and type of data that we may receive
                   from it.  You should adjust your privacy settings to your preferences on those social media outlets.</p>
            
            
            <p>Where VTHelplink.org  discloses your personal information to its agents or sub-contractors (“Outside Contractors”) for
             these purposes, the agent or sub-contractor in question will be obligated to use that personal information in accordance
              with the terms of this privacy statement. Access to your personally identifiable information by these contractors is
               limited to the information reasonably necessary in order for these Outside Contractors to perform their limited function
                for us. We also require that these contractors (i) protect the privacy of your personally identifiable information 
                consistent with this Privacy Policy, and (ii) not use or disclose your personally identifiable information for any purpose
                 other than providing us with products or services for which we contracted.  In addition to the disclosures reasonably
                  necessary for the purposes identified elsewhere above, VTHelplink.org  may disclose your personal information to the
                   extent that it is required to do so by law, in connection with any legal proceedings or prospective legal proceedings,
                    and in order to establish, exercise or defend its legal rights.
            </p>
            <h3>Cookies and other tracking technology</h3>
            <p>
            We, and third parties doing business with the site including outside contractors, may use cookies, web beacons, local shared objects, tags, scripts, stationary and mobile advertising identifiers and like technologies (“Cookies”) in connection with use of this site by you, mobile applications and  third party websites. Cookies may have unique identifiers, and be present on your computer or mobile device they may also be present on the site or in emails that we send to you. Information about you and your use of the site may be transmitted by Cookies including, without limitation, information concerning your type of browser, device identifier, IP address, search preferences, the date and time of your use, your click through activity with respect to advertisements and time, location and length of visit. Cookies may be stored only during an individual session or indefinitely.</p>
            <p>
            VTHelplink.org  may use Cookie-gathered data  in order to: (i) facilitate your use of the site such as remembering information to avoid you having to subsequently re-enter it on your next visit to the site; (ii) customize  your experience on the site; (iii) collect aggregate metrics such as aggregate visitors, demographic usage, geographic usage and total traffic to the site; (iv) for quality assurance purposes and to correct malfunctions; and (v) to provide site enhancements and evaluations.</p>
            <p>
            Outside Contractors and other linked sites may use Cookies and collect and use your information derived therefrom differently than VTHelplink.org. You must refer to their applicable terms regarding the same.  We are not responsible for the use of Cookies and collection and use of your information for anyone other than us.
            </p>
            <h3>Securing your data</h3>
            <p>
            VTHelplink.org  uses commercially reasonable physical, administrative, and technological safeguards to preserve the integrity and security of all information we collect and that we share with our Outside Contractors, users and service providers. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.
            </p>
            <h3>
            Updating this statement</h3>
            <p>We may revise this privacy policy from time to time. The most current version of the privacy policy will govern our collection, use, and disclosure of information about you and will be located on the site.  By continuing to access the site or use any services and products related thereto after those changes become effective, you agree to the revised privacy policy.
            </p>
            <h3>Other websites</h3>
            <p>
            This website contains links to other websites.  VTHelplink.org  is not responsible for the privacy policies or practices of any third party. These references and links are provided as aids to help you identify and locate other resources that may be of interest, and are not intended to state or imply that  VTHelplink.org  sponsors, endorses or is affiliated or associated with the persons or entities who provide such information, products or services, nor are the references or links intended to state or imply that  VTHelplink.org  is legally authorized to use any trade name, registered trademark, logo, legal or official seal, or copyright symbol that may be reflected in them.
            </p>
            
            
            <h3>Contact VTHelplink.org </h3>
            <p>
            If you have any questions about this privacy policy or VTHelplink.org ’s treatment of your personal information, please write to:
            Health Resources in Action
            2 Boylston St. 4th Floor
            Boston, MA 02116
            
            </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
            
            
              </div>
            
            
              <div class="col-md-3 mycs11 animate__animated animate__fadeIn animate__slow">
                    <!-- bg-free-material.jpg -->
                <img src="../../../assets/bg-free-material.jpg" class="img-fluid">
                <h3 class="vt-m">Promote the use of the Helplink in your community.</h3>
                <div class="line-vt"></div>
                <p><small>Help connect people to the services they need with VT Helplink cards, posters, and other items.</small></p>
                <div class="line-vt-100 mb-10"></div>
                <!-- <a href="https://vadic.org/product-category/vermont-resources/vt-helplink/" target="_blank" class="vt-color-2 link">Order materials now. </a>  -->
              </div>
            </div>
            </div>
            
            </div>
            </section>
              




            <section id="about-section-connection" class="about-sec3">


                    <div class="container-fluid w-100">
                     <div class="row ">	
                      <div class="col-md-5 img-section-hfy-vt hide-mob"></div>
                   
                      <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
                       <small>Your confidential connection.</small>
                       <div class="line-vt-100"></div>
                   
                       <h2 class="font-weight-bold">We’re here to connect you to the services you need.</h2>
                   
                     </div>
                     <div class="col-md-4 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call ">		
                       <p class="line-h-20 mt-2"><small>You might be concerned about someone, or struggling with substance use yourself. Our caring, trained Specialists will answer your questions and guide you to all available resources.</small></p>
                   
                       <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
                        <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
                      </div> 
                    </div>
                    <!-- /euf/assets/client/img/bg-bottom-about-us.jpg -->
                   <img src="../../../assets/bg-bottom-about-us.jpg" class="img-fluid mt-2 mb-2 sw-mob">
                   
                   </div>
                   
                   </div>
                   <div class="chat-box"> </div>
                   </section>
          </div>
