import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-spa-result',
  templateUrl: './spa-result.component.html',
  styleUrls: ['./spa-result.component.scss']
})
export class SpaResultComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { 

  }

  ngOnInit(): void 
  {
    let incidentid = this.activatedRoute.snapshot.params.incidentid;
    if(incidentid){
      window.location.href= "https://hub.vthelplink.org/spa_result/incident_id/"+incidentid;
    }else{
      window.location.href= "https://hub.vthelplink.org/spa_result/";

    }
  }

}
