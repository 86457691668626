import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from './pages/home/home.component';
import { NeedhelpComponent } from './pages/needhelp/needhelp.component';
import { LovedneedHelpComponent } from './pages/lovedneed-help/lovedneed-help.component';
import { ClientneedHelpComponent } from './pages/clientneed-help/clientneed-help.component';
import { AboutTreatmentComponent } from './pages/about-treatment/about-treatment.component';
import { PregnantParentingComponent } from './pages/pregnant-parenting/pregnant-parenting.component';
import { GeneralPreventionComponent } from './pages/general-prevention/general-prevention.component';
import { OverdosePreventionComponent } from './pages/overdose-prevention/overdose-prevention.component';
import { CovidResourcesComponent } from './pages/covid-resources/covid-resources.component';
import { ProviderLoginComponent } from './pages/provider-login/provider-login.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { FindhelpComponent } from './pages/findhelp/findhelp.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { ContentDataResolver } from './resolvers/contentdata.resolver';
import { TreatmentDataResolver } from './resolvers/about-treatment.resolver';
import { AnswerComponent } from './answer/answer.component';
import { SpaResultComponent } from './spa-result/spa-result.component';
import { HarmReductionComponent } from './pages/harm-reduction/harm-reduction.component';
const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'app/home', component: HomeComponent, pathMatch: 'full' },
  { path: 'app/needhelp' , component: NeedhelpComponent, resolve: { data: ContentDataResolver}},
  { path: 'app/my-loved-one-needs-help', component: LovedneedHelpComponent},
  { path: 'app/my-client-needs-help', component: ClientneedHelpComponent },
  { path: 'app/treatment-recovery', component: AboutTreatmentComponent,
  resolve: { data: TreatmentDataResolver} },
  { path: 'app/pregnant-parenting', component: PregnantParentingComponent},
  { path: 'app/general-prevention', component: GeneralPreventionComponent,
  resolve: { data: ContentDataResolver}},
  { path: 'app/overdose-prevention', component: OverdosePreventionComponent,
  resolve: { data: ContentDataResolver}},
  { path: 'app/covid-resource', component: CovidResourcesComponent,
  resolve: { data: ContentDataResolver}},
  { path: 'app/login', component: ProviderLoginComponent},
  { path: "app/forgot-password", component: ForgotpasswordComponent},
  { path: "app/account/findhelp", component:FindhelpComponent},
  { path: "app/aboutus", component: AboutusComponent},
  { path: "app/contactus", component: ContactusComponent},
  {path: 'app/answers/answer_id/:answerid', component: AnswerComponent, pathMatch: 'full'},  
  {path: 'app/account/opa_result/incident_id/:incidentid', component: SpaResultComponent, pathMatch: 'full'},
  {path: 'app/account/opa_result', component: SpaResultComponent, pathMatch: 'full'},
  {path: 'app/saferuse', component: HarmReductionComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
