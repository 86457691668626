import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-findhelp',
  templateUrl: './findhelp.component.html',
  styleUrls: ['./findhelp.component.scss']
})
export class FindhelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href= "https://hub.vthelplink.org/findhelp";
  }

}
