import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  selectedLang = new BehaviorSubject('');
  pageName = new BehaviorSubject('');

  /* =============== Start English API's =================*/
getDynamicContentData(): Observable<any>{
 return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/');
}
getLoveOneNeedHelp(): Observable<any> {
  return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/33');
}
getClientNeedHelp(): Observable<any> {
  return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/35');
}
getTreatmentRecovery(): Observable<any> {
  return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/37');
}
getPregnentParenting(): Observable<any> {
  return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/155');
}

getAboutData(): Observable<any> {
  return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/7');
}






  getHelpData(): Observable<any> {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/344');
  }

  Covid19Resoures(): Observable<any> {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/5646');
  }

  helpSomeone = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/350');
  }

  staySafe = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/5757');
  }

  stopOverdose = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/357');
  }

  home = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/156');
  }

  getEvents = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/tribe/events/v1/events');
  }

  getStories = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/1106');
  }

  Aboutus = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/2');
  }

  digitalDownloads = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/437');
  }

  allHighlights = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/posts');
  }

  termsandconditions = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/326');
  }

  privacypolicy = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/324');
  }

  /* =============== End English API's =================*/



  /* =============== Start Spanish API's =================*/

  getHelpEs(): Observable<any> {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/538');
  }

  AboutusEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/533');
  }

  allHighlightsEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/posts?lang=es');
  }

  Covid19ResouresEs(): Observable<any> {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/5702');
  }

  digitalDownloadsEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/541');
  }

  helpSomeoneEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/539');
  }

  privacypolicyEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/536');
  }

  staySafeEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/5759');
  }

  stopOverdoseEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/540');
  }

  getStoriesEs = (): Observable<any> => {
    return this.http.get('assets/json/storiesEs.json');
  }

  termsandconditionsEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/537');
  }

  homeEs = (): Observable<any> => {
    return this.http.get('https://e.helplineil.org/wp-json/wp/v2/pages/535');
  }
  
  /* =============== End Spanish API's =================*/
}
