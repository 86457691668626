import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-pregnant-parenting',
  templateUrl: './pregnant-parenting.component.html',
  styleUrls: ['./pregnant-parenting.component.scss']
})
export class PregnantParentingComponent implements OnInit {
  title: string;
  content: string;
  constructor(private services: DataService) { }

  ngOnInit(): void {
    this.services.getPregnentParenting().subscribe(res => {
      console.log(JSON.stringify(res));
      if (res){
this.title = res.title.rendered;
this.content = res.content.rendered;
      }
      // for(var j=0; j<=data.length-1;j++)
      // {
      //     if(data[j].id!='31')
      //         continue;
      //      if(data[j].id=='31')
      //      {
      //         var ineedhelptitle = data[j].title.rendered;
      //         var ineedhelpcontent =  data[j].content.rendered;
      //         $('#ineedhelp-title').html(ineedhelptitle);
      //         $('#ineedhelp-content').html(ineedhelpcontent);
      //         var contnt=$('#ineedhelp-content p').eq(0).text();
      //          $('#ineedhelp-content p').eq(0).html('<h3 class="vt-m mt-3">'+contnt+'</h3>');
      //      }
      // }
    })
  }
}
