import { Component, OnInit } from '@angular/core';
import { ContentDataResolver } from 'src/app/resolvers/contentdata.resolver';

@Component({
  selector: 'app-overdose-prevention',
  templateUrl: './overdose-prevention.component.html',
  styleUrls: ['./overdose-prevention.component.scss']
})
export class OverdosePreventionComponent implements OnInit {
  title: string;
  accordion: string;
  leftsideBarData: string;
  subContent: string;
  constructor(private services: ContentDataResolver) { }

  ngOnInit(): void {
    this.subContent = '';
    this.services.contentData.subscribe(res => {
      if (res){
        let data = res.find((ele: any) => ele.id === 40);
        this.title = data.title.rendered;
        this.accordion = data.content.rendered
        console.log(this.accordion);
        this.leftsideBarData = data.acf.left_sidebar;
              }

    })
  }
}
