import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { TreatmentDataResolver } from 'src/app/resolvers/about-treatment.resolver';

@Component({
  selector: 'app-about-treatment',
  templateUrl: './about-treatment.component.html',
  styleUrls: ['./about-treatment.component.scss']
})
export class AboutTreatmentComponent implements OnInit {
  title: string;
  content: string;
  leftContent: string;
  constructor(private services: TreatmentDataResolver) { }

  ngOnInit(): void {
    this.services.treatmentData.subscribe(res => {
      if (res !== null){
this.title = res.title.rendered;
this.content = res.content.rendered;
this.leftContent = res.acf.left_sidebar;
      }
    },
    (error) => {
console.log(error);
    }
    )
  }

}
