import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { 

  }

  ngOnInit(): void 
  {
    let answerid = this.activatedRoute.snapshot.params.answerid;
    window.location.href= "https://hub.vthelplink.org/answer/"+answerid;
  }

}
