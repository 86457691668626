<div ng-app="odpreventionApp" ng-controller="odpreventionController">
  <section id="hero" class="text-center d-flex relative">
    <div class="col-md-8 hero-slide hide-mob"></div>
    <div class="col-md-4 right-set">
      <div class="hero-container">
        <h1 class=" wow animate__animated animate__fadeInDown animate__slown"
          style="visibility: visible; animation-name: fadeInDown;">Connecting you and your loved ones</h1>
        <h1 class="wow fadeInDown vt-color-2">to overdose prevention</h1>

        <div class="line-vt"></div>
        <div class="wow mycs13 animate__animated animate__fadeInDown animate__slow intro-copy  d-none d-sm-block"
          data-wow-delay=".0s" style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown;">
          Helplink will help you save a life, whether it be yours or someone you love.</div>

        <div class="help-box  wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s"
          style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
          <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
                    <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
                    <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
        </div>
      </div>
    </div>
    <!-- <div class="vt-bg-trans-logo"></div> -->
  </section>
  <img src="../../../assets/bnr--od-prevention-mob.jpg" class="img-fluid sw-mob mt-2">
  <section id="overdose-section-2">
    <div class="bg-white-vt relative">
      <div class="container-fluid w-92 pt-5 pb-5" style="z-index: 9;    position: relative;">
        <div class="row ">
          <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
            <div class="sce-head1">Keeping you and your loved ones alive</div>
            <div class="line-vt-100"></div>
            <h2 class="font-weight-bold" [innerHTML]="title"></h2>
            <div class="line-vt"></div>

            <h3 class="vt-m mt-3">Opioid overdose deaths can be prevented, and anyone can be the one to stop an
              overdose. </h3>
            <p class="mt-4"><small>
                Opioid overdose deaths can be prevented, and anyone can be the one to stop an overdose. It’s important
                to know the signs of overdose and steps to take, including using Naloxone (brand name Narcan®). If you
                or a loved one uses <a routerLink="/app/treatment-recovery"
                  class="vt-color-2">prescription opioids or heroin</a>, make sure you carry naloxone. In an emergency,
                you can save someone, or someone else can save you.
              </small>
            </p>
            <div id="leftsideBarData" [innerHTML]="leftsideBarData"></div>
          </div>

          <!------------------------------------------ accordion section ---------------------------------->
          <div class="col-md-6 mycs10 wow animate__animated animate__fadeIn animate__slow">
            <div id="accordion" class="myaccordion" [innerHTML]="accordion">
            </div>
          </div>

          <div class="col-md-3 mycs11 wow animate__animated animate__fadeIn animate__slow" style="visibility: visible; animation-name: fadeIn;">
            <img src="../../../assets/OD_Prevention-img-1.jpg" class="img-fluid">
            <h3 class="thmb-heading">Be prepared with naloxone.</h3>
            <div class="line-vt"></div>
            <p><small>Carry naloxone with you and know how to use it. Get naloxone <a
                  href="app/answers/answer_id/2">at one of these sites</a> or at a
                pharmacy.</small></p>
            <div class="line-vt-100 mb-10"></div>
            <a href="https://www.youtube.com/watch?list=PLk9-Nj2hBiYrqLKf5pWFwcbhKp3uxKikB&time_continue=56&v=GnGMkJSu4gU&feature=emb_logo"
              target="_blank" class="vt-color-2 link"> Learn the signs of overdose with this video.</a>
            <a href="https://www.healthvermont.gov/sites/default/files/documents/pdf/RESP_Narcan_HowToGiveBrochure2016.pdf"
              target="_blank" class="vt-color-2 link"> Learn how to give naloxone. </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="overdose-pre" class="img-section-hfy-vt">
    <div class="container-fluid w-100  ">
      <div class="row ">
        <div class="col-md-5 "></div>
        <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
          <div class="sce-head1">Life is precious.</div>
          <div class="line-vt-100"></div>

          <h2 class="font-weight-bold mycs12">We’re here to help you save a life. Fatal overdoses can be prevented.</h2>

        </div>
        <div class="col-md-4 wow animate__animated animate__fadeIn animate__slow mt-40 pt-6 bt-call ">
          <p class="line-h-20 mt-2"><small>You might be concerned about someone, or struggling with substance use
              yourself. Our caring, trained specialists will give you the support you need to keep your loved ones
              alive.</small></p>

          <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
            <div class="bt1 btn65"><a href="tel:8025655465" class="btn-get-started hero-button">Call</a></div>
            <div class="bt2 top"><a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">Text</a></div>
            <div class="bt2 top"><button class="btn-get-started hero-button" onclick="showchat()">Chat</button></div>
          </div>
        </div>
        <img src="../../../assets/bg-vt-section-od-prevention.jpg" class="img-fluid sw-mob">

      </div>

    </div>
    <div class="chat-box"> </div>
  </section>
</div>