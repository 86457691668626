import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ilhelpline';
  pageName: string;
  currentLang: string;

  constructor(private router:Router) {
    // const navEndEvent$ = router.events.pipe(
    //   filter(e => e instanceof NavigationEnd)
    // );
    // navEndEvent$.subscribe((e: NavigationEnd) => {
    //   console.log(e);
    // })
    this.router.events.subscribe(value => {
      //console.log('current route: ', value.);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });

  }
}
